import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm George - currently working as a Software Developer for `}<a href="//autodesk.co.uk" target="_blank" rel="noopener noreferrer">{`Autodesk`}</a>{`, in the `}<a parentName="p" {...{
        "href": "//construction.autodesk.com/"
      }}>{`Autodesk Construction cloud`}</a>{` product team.`}</p>
    <p>{`I'm based in South East Manchester, a region of `}<a parentName="p" {...{
        "href": "https://maps.app.goo.gl/H21h691jhq9xV3PM9"
      }}>{`Greater Manchester`}</a>{` in the United Kingdom.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      